import { lazy } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

// Layout

const ProtectedLayout = lazy(() => import('./ProtectedLayout.js'));

// Errors

const ForbiddenPage = lazy(() => import('./Pages/ForbiddenPage/ForbiddenPage.js'));

const Error404Page = lazy(() => import('./Pages/Error404Page/Error404Page.js'));

// Страницы пользователя

const userRoutes = {
	path: 'user',
	children: [
		{
			index: true,
			Component: lazy(() => import('./Pages/AccountPage/AccountPage.js'))
		}
	]
};

// Научный работник

const scientistRoutes = {
	path: 'scientist',
	children: [
		{
			index: true
		},
		{
			path: 'database',
			Component: lazy(() => import('./Pages/Scientist/DatabasePage/DatabasePage.js'))
		},
		{
			path: 'details/:id',
			Component: lazy(() => import('./Pages/Scientist/ProfilePage/ProfilePage.js'))
		}
	]
};

// Научная организация

const scienceOrgRoutes = {
	path: 'scienceOrg',
	children: [
		{
			index: true
		},
		{
			path: 'database',
			Component: lazy(() => import('./Pages/ScienceOrg/DatabasePage/DatabasePage.js'))
		},
		{
			path: 'details/:id',
			Component: lazy(() => import('./Pages/ScienceOrg/ProfilePage/ProfilePage.js'))
		}
	]
};

// Публикация

const publicationRoutes = {
	path: 'publication',
	children: [
		{
			index: true
		},
		{
			path: 'database',
			Component: lazy(() => import('./Pages/Publications/DatabasePage/DatabasePage.js'))
		},
		{
			path: 'details/:id',
			Component: lazy(() => import('./Pages/Publications/PublicationInfoPage/PublicationInfoPage.js'))			
		}
	]
};

// Научный проект

const scienceProjectRoutes = {
	path: 'scienceProject',
	children: [
		{
			index: true
		},
		{
			path: 'database',
			Component: lazy(() => import('./Pages/Projects/DatabasePage/DatabasePage.js'))
		},
		{
			path: 'details/:id',
			Component: lazy(() => import('./Pages/Projects/ProjectProfilePage/ProjectProfilePage.js'))
		}
	]
};

// Конкурс Лучший научный работник

const scienceEmployeeContestRoutes = {
	path: 'scienceEmployeeContest',
	children: [
		{
			index: true,
			Component: lazy(() => import('./Pages/EmployeeContest/ContestInfoPage/ContestInfoPage.js'))
		},
		{
			path: 'admin',
			children: [
				{	
					index: true,
					Component: lazy(() => import('./Pages/EmployeeContest/Admin/ProfilePage/ProfilePage.js'))
				},				
				{
					path: 'submissions',
					Component: lazy(() => import('./Pages/EmployeeContest/Admin/SubmissionsPage/SubmissionsPage.js'))
				},				
				{
					path: 'submission/:id',
					Component: lazy(() => import('./Pages/EmployeeContest/Admin/SubmissionDetailsPage/SubmissionDetailsPage.js'))
				}
			]
		},
		{
			path: 'expert',
			children: [
				{	
					index: true,
					Component: lazy(() => import('./Pages/EmployeeContest/Expert/ProfilePage/ProfilePage.js'))
				},					
				{
					path: 'reviews',
					Component: lazy(() => import('./Pages/EmployeeContest/Expert/ReviewsListPage/ReviewsListPage.js'))
				},
				{
					path: 'review/:id',
					Component: lazy(() => import('./Pages/EmployeeContest/Expert/ReviewPage/ReviewPage.js'))
				}
			]
		},
		{
			path: 'sectionHead',
			children: [
				{	
					index: true,
					Component: lazy(() => import('./Pages/EmployeeContest/SectionHead/ProfilePage/ProfilePage.js'))
				},
			]
		},			
		{
			path: 'participant',
			children: [
				{
					index: true,
					Component: lazy(() => import('./Pages/EmployeeContest/Participant/ParticipantProfilePage/ParticipantProfilePage.js'))
				},
				{
					path: 'submission',
					Component: lazy(() => import('./Pages/EmployeeContest/Participant/SubmissionPage/SubmissionPage.js'))
				},
				{
					path: 'points',
					Component: lazy(() => import('./Pages/EmployeeContest/Participant/PointsPage/PointsPage.js'))
				},
				{
					path: 'states',
					Component: lazy(() => import('./Pages/EmployeeContest/Participant/StatesHistoryPage/StatesHistoryPage.js'))
				},
				{
					path: 'appellation',
					Component: lazy(() => import('./Pages/EmployeeContest/Participant/AppellationPage/AppellationPage.js'))
				}
			]
		},
		{
			path: 'qna',
			Component: lazy(() => import('./Pages/EmployeeContest/QAPage/QAPage.js'))
		}
	]
};

// Премии в области науки

const scienceAwardsContestRoutes = {
	path: 'scienceAwardsContest',
	children: [
		{
			index: true,
			Component: lazy(() => import('./Pages/ScienceAwardsContest/InfoPage/InfoPage.js'))
		},
		{
			path: 'admin',
			children: [
				{	
					index: true,
					Component: lazy(() => import('./Pages/ScienceAwardsContest/Admin/ProfilePage/ProfilePage.js'))
				},
				{
					path: 'submissions',
					Component: lazy(() => import('./Pages/ScienceAwardsContest/Admin/SubmissionsPage/SubmissionsPage.js'))
				}
			]
		},
		{
			path: 'expert',
			children: [
				{	
					index: true,
					Component: lazy(() => import('./Pages/ScienceAwardsContest/Expert/ProfilePage/ProfilePage.js'))
				},				
				{
					path: 'reviews',
					Component: lazy(() => import('./Pages/ScienceAwardsContest/Expert/ReviewsListPage/ReviewsListPage.js'))
				},
				{
					path: 'review/:id',
					Component: lazy(() => import('./Pages/ScienceAwardsContest/Expert/ReviewPage/ReviewPage.js'))
				}
			]
		},
		{
			path: 'sectionHead',
			children: [
				{	
					index: true,
					Component: lazy(() => import('./Pages/ScienceAwardsContest/SectionHead/ProfilePage/ProfilePage.js'))
				},
			]
		},		
		{
			path: 'participant',
			children: [
				{
					index: true,
					Component: lazy(() => import('./Pages/ScienceAwardsContest/Participant/ParticipantProfilePage/ParticipantProfilePage.js'))
				},
				{
					path: 'submission',
					Component: lazy(() => import('./Pages/ScienceAwardsContest/Participant/SubmissionPage/SubmissionPage.js'))
				},
				{
					path: 'points',
					Component: lazy(() => import('./Pages/ScienceAwardsContest/Participant/PointsPage/PointsPage.js'))
				},
				{
					path: 'states',
					Component: lazy(() => import('./Pages/ScienceAwardsContest/Participant/StatesPage/StatesPage.js'))
				},
				{
					path: 'appellation',
					Component: lazy(() => import('./Pages/ScienceAwardsContest/Participant/AppellationPage/AppellationPage.js'))
				}
			]
		},
		{
			path: 'qna',
			Component: lazy(() => import('./Pages/ScienceAwardsContest/QAPage/QAPage.js'))
		}
	]
};

// Государственные научные стипендии

const scienceScholarshipContestRoutes = {
	path: 'scholarshipContest',
	children: [
		{
			index: true,
			Component: lazy(() => import('./Pages/GovScienceScholarship/InfoPage/InfoPage.js'))
		},
		{
			path: 'admin',
			children: [
				{	
					index: true,
					Component: lazy(() => import('./Pages/GovScienceScholarship/Admin/ProfilePage/ProfilePage.js'))
				},
				{
					path: 'submissions',
					Component: lazy(() => import('./Pages/GovScienceScholarship/Admin/SubmissionsPage/SubmissionsPage.js'))
				}
			]
		},
		{
			path: 'expert',
			children: [
				{	
					index: true,
					Component: lazy(() => import('./Pages/GovScienceScholarship/Expert/ProfilePage/ProfilePage.js'))
				},
				{
					path: 'reviews',
					Component: lazy(() => import('./Pages/GovScienceScholarship/Expert/ReviewsListPage/ReviewsListPage.js'))
				},
				{
					path: 'review/:id',
					Component: lazy(() => import('./Pages/GovScienceScholarship/Expert/ReviewPage/ReviewPage.js'))
				}
			]
		},
		{
			path: 'sectionHead',
			children: [
				{	
					index: true,
					Component: lazy(() => import('./Pages/GovScienceScholarship/SectionHead/ProfilePage/ProfilePage.js'))
				},
			]
		},
		{
			path: 'participant',
			children: [
				{
					index: true,
					Component: lazy(() => import('./Pages/GovScienceScholarship/Participant/ParticipantProfilePage/ParticipantProfilePage.js'))
				},
				{
					path: 'submission',
					Component: lazy(() => import('./Pages/GovScienceScholarship/Participant/SubmissionPage/SubmissionPage.js'))
				},
				{
					path: 'points',
					Component: lazy(() => import('./Pages/GovScienceScholarship/Participant/PointsPage/PointsPage.js'))
				},
				{
					path: 'states',
					Component: lazy(() => import('./Pages/GovScienceScholarship/Participant/StatesPage/StatesPage.js'))
				},
				{
					path: 'appellation',
					Component: lazy(() => import('./Pages/GovScienceScholarship/Participant/AppellationPage/AppellationPage.js'))
				}
			]
		},		
		{
			path: 'qna',
			Component: lazy(() => import('./Pages/GovScienceScholarship/QAPage/QAPage.js'))
		}
	]
};

//

const portalRoutes = {
		path: "portal",
		children: [
			userRoutes,
			scientistRoutes,
			scienceOrgRoutes,
			scienceProjectRoutes,
			publicationRoutes,
			scienceEmployeeContestRoutes,
			scienceAwardsContestRoutes,
			scienceScholarshipContestRoutes
		]
};

// Router

const router = createBrowserRouter([
	{
		path: "/",
		element: <ProtectedLayout />,
		errorElement: <Error404Page />,
		children: [
			{
				index: true,
				Component: lazy(() => import('./Pages/HomePage/HomePage.js'))
			},
			{
				path: "login",
				Component: lazy(() => import('./Pages/LoginPage/LoginPage.js'))
			},
			{
				path: "passwordRestoreRequest",
				Component: lazy(() => import('./Pages/PasswordRestoreRequestPage/PasswordRestoreRequestPage.js'))
			},
			{
				path: "passwordRestore",
				Component: lazy(() => import('./Pages/PasswordRestorePage/PasswordRestorePage.js'))				
			},
			{
				path: "registration",
				Component: lazy(() => import('./Pages/RegistrationPage/RegistrationPage.js'))				
			},
			{
				path: "postRegistration",
				Component: lazy(() => import('./Pages/PostRegistrationPage/PostRegistrationPage.js'))				
			},
			portalRoutes
		]
	}
]);

//

function AppRouter() {

  return <RouterProvider router={router} />;

}

export default AppRouter;