import { Suspense } from 'react';

// Router

import AppRouter from './AppRouter.js';

// CONTEXT

import { LocalizationProvider } from './Context/LocalizationContext.js';
import { AuthProvider } from './Context/AuthContext.js';

import Loader from './Components/Loader/Loader.js';

import NotificationContainer from './Components/Notification/NotificationContainer.js';

function App() {

  return (
    <div className="app">
      <LocalizationProvider>
        <AuthProvider>
          <Suspense fallback={<Loader />}>
            <AppRouter />
          </Suspense>
        </AuthProvider>
      </LocalizationProvider>
      <NotificationContainer />
    </div>
  );

}

export default App;